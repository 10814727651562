import React from "react"
import { Link } from "gatsby"
import { useSpring, animated, config } from "react-spring"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export function CalendarItem({
  title,
  itemId,
  date,
  img,
  slug,
  empty = false,
}) {
  const [dropdownMenu, setDropdownMenu] = useSpring(() => ({
    transform: "scale(1)",
    config: config.wobbly,
  }))

  const image = img && getImage(img.localFile)

  return (
    <button
      key={itemId}
      disabled={empty}
      onMouseEnter={() => {
        !empty &&
          setDropdownMenu({
            transform: "scale(1.5)",
            config: config.wobbly,
          })
      }}
      onMouseLeave={() => {
        !empty &&
          setDropdownMenu({
            transform: "scale(1)",
            config: config.wobbly,
          })
      }}
      className={`relative inline-block shadow rounded-b-2xl overflow-hidden  disabled:cursor-default ${
        !empty && "hover:shadow-2xl hover:scale-105"
      } transition-all ${empty ? "grayscale" : ""}`}
    >
      {!empty ? (
        <Link to={`/event/${slug}`}>
          <animated.div
            style={dropdownMenu}
            className="absolute left-8 w-12 h-16 bg-fontpurple-50 rounded-b-xl p-2 pb-3 text-white z-50"
          >
            <span className="text-sm">
              {date.dayWord} <br />
            </span>
            <span className="text-lg font-primary-bold m-0">
              {date.dayNumber}
            </span>
          </animated.div>
          {!img && (
            <div
              className="rounded-t-xl "
              style={{
                backgroundImage: `${!empty && `url(${img})`}`,
                backgroundColor: `${empty && "#D3D3D3"}`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                minHeight: "200px",
              }}
            />
          )}
          {img && (
            <GatsbyImage
              image={image}
              className="rounded-t-xl h-[200px] w-full"
              objectFit="cover"
            />
          )}
          <div className="bg-white py-8 px-4 flex justify-center items-center">
            <h3 className="text-fontpurple-50 font-semibold text-xs text-center lg:text-sm w-auto font-primary-bold text-center">
              {title}
            </h3>
          </div>
        </Link>
      ) : (
        <>
          <animated.div
            style={dropdownMenu}
            className="absolute left-8 w-12 h-16 bg-fontpurple-50 rounded-b-xl p-2 pb-3 text-white "
          >
            <span className="text-sm">
              {date.dayWord} <br />
            </span>
            <span className="text-lg font-primary-bold m-0">
              {date.dayNumber}
            </span>
          </animated.div>
          <div
            className="rounded-t-xl "
            style={{
              backgroundImage: `${!empty && `url(${img})`}`,
              backgroundColor: `${empty && "#D3D3D3"}`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              minHeight: "200px",
            }}
          />
          <div className="bg-white py-8 px-4 flex justify-center items-center">
            <h3 className="text-fontpurple-50 font-semibold text-xs text-center lg:text-sm w-auto font-primary-bold text-center">
              {title}
            </h3>
          </div>
        </>
      )}
    </button>
  )
}
