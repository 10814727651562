import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useSpring, animated, config } from "react-spring"
export function EventListItem({ title, itemId, date, img, slug }) {
  const image = img && getImage(img.localFile)
  const [dropdownMenu, setDropdownMenu] = useSpring(() => ({
    transform: "scale(1)",
    config: config.stiff,
  }))

  return (
    <Link
      to={`/event/${slug}`}
      onMouseEnter={() => {
        setDropdownMenu({
          transform: "scale(1.02)",
          config: config.stiff,
        })
      }}
      onMouseLeave={() => {
        setDropdownMenu({
          transform: "scale(1)",
          config: config.stiff,
        })
      }}
    >
      <div
        key={itemId}
        role="button"
        tabIndex={0}
        className="inline-block mb-4 lg:mb-0 rounded-full w-full transition-all  hover:scale-[1.02] h-full"
      >
        <GatsbyImage
          image={image}
          className="rounded-t-3xl h-[350px] w-full"
          objectFit="cover"
          // style={{
          //   backgroundImage: `url(${img})`,
          //   backgroundSize: "cover",
          //   backgroundPosition: "center",
          //   height: "243px",
          // }}
        />
        <div className="bg-white rounded-b-3xl shadow-md py-4 pb-6 px-4 flex flex-col items-start md:flex-row md:justify-between md:items-center md:pb-14 md:pt-6 font-primary-bold">
          <animated.h3
            style={dropdownMenu}
            className="text-fontpurple-50 text-xl pb-4 lg:pb-0 lg:text-xl w-auto font-primary-bold"
          >
            {title}
          </animated.h3>
          <animated.div
            style={dropdownMenu}
            className="flex flex-row justify-between w-full md:w-auto items-center mt-4 md:mt-[unset]"
          >
            <p className="border-2 border-fontpurple-50 text-fontpurple-50 text-xs lg:text-sm rounded-full p-2 px-4">
              {date}
            </p>

            <FontAwesomeIcon
              icon={faChevronRight}
              className="h-4 w-4 md:hidden text-fontpurple-50"
            />
          </animated.div>
        </div>
      </div>
    </Link>
  )
}
