import React, { useEffect, useMemo } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Header } from "../components/typography"
import { OutlinedButton } from "../components/buttons"
import { LineSelect } from "../components/inputs"
import { CalendarItem } from "../components/calendar-item"
import { EventListItem } from "../components/event-list-item"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendar, faList } from "@fortawesome/free-solid-svg-icons"
import test from "../images/test-image.png"
import LatestNews from "../containers/latest-news"
import ThreeColumnGallery from "../containers/three-column-gallery"
import uniqBy from "lodash/uniqBy"
import Instagram from "../containers/instagram"
const months = [
  { id: 1, label: "January" },
  { id: 2, label: "February" },
  { id: 3, label: "March" },
  { id: 4, label: "April" },
  { id: 5, label: "May" },
  { id: 6, label: "June" },
  { id: 7, label: "July" },
  { id: 8, label: "August" },
  { id: 9, label: "September" },
  { id: 10, label: "October" },
  { id: 11, label: "November" },
  { id: 12, label: "December" },
]

const formatEventDateToDisplay = event => {
  const formattedDateFrom = new Date(
    new Date(event.dateTimeFrom).getUTCFullYear(),
    new Date(event.dateTimeFrom).getUTCMonth(),
    new Date(event.dateTimeFrom).getUTCDate()
  )
  const formattedDateTo = new Date(
    new Date(event.dateTimeTo).getUTCFullYear(),
    new Date(event.dateTimeTo).getUTCMonth(),
    new Date(event.dateTimeTo).getUTCDate()
  )
  const monthFrom = formattedDateFrom.getUTCMonth() + 1
  const monthTo = formattedDateTo.getUTCMonth() + 1
  const monthFromString = formattedDateFrom.toLocaleDateString("en-US", {
    month: "short",
  })

  const dateToDisplay =
    monthFrom !== monthTo
      ? `${formattedDateFrom.toLocaleDateString("en-US", {
          day: "numeric",
          month: "short",
        })} -
            ${formattedDateTo.toLocaleDateString("en-US", {
              day: "numeric",
              month: "short",
            })}`
      : `${formattedDateFrom.toLocaleDateString("en-US", {
          day: "numeric",
          month: "short",
        })} -
            ${formattedDateTo.getDate()}`

  return dateToDisplay
}

const Events = ({ data, location }) => {
  console.log(data)
  const clubs = data.allStrapiClub.edges.map(({ node }) => {
    return {
      id: node.id,
      label: node.clubName,
      events: node.events,
    }
  })

  const allAllEvents = []
  clubs.forEach(club => {
    club.events.forEach(event => {
      allAllEvents.push(event)
    })
  })

  const allEvents = uniqBy(allAllEvents, "id")

  // calendar state
  const [club, setClub] = React.useState({ id: "all", label: "Clubs" })
  const [month, setMonth] = React.useState({ id: "all", label: "Months" })
  const [display, setDisplay] = React.useState("list")
  const daysInMonth = new Date(new Date().getYear(), month.id, 0).getDate()

  const allowAllClubsOption = display === "list"

  useEffect(() => {
    if (display === "calendar" && club.id === "all") {
      setClub(clubs[0])
      if (month.id === "all") {
        setMonth(months[new Date().getMonth()])
      }
    }
  }, [display, month, club])

  const allEventsByMonth = useMemo(() => {
    const result = []
    if (month.id === "all") {
      allEvents.forEach(event => {
        console.log(event)
        result.push(
          <EventListItem
            title={event.title}
            itemId={event.id}
            slug={event.slug}
            date={formatEventDateToDisplay(event)}
            img={event.image}
          />
        )
      })
    } else {
      allEvents.forEach(event => {
        const formattedDateFrom = new Date(
          new Date(event.dateTimeFrom).getUTCFullYear(),
          new Date(event.dateTimeFrom).getUTCMonth(),
          new Date(event.dateTimeFrom).getUTCDate()
        )
        const monthFrom = formattedDateFrom.getUTCMonth() + 1
        if (monthFrom === month.id) {
          result.push(
            <EventListItem
              title={event.title}
              itemId={event.id}
              slug={event.slug}
              date={formatEventDateToDisplay(event)}
              img={event.image}
            />
          )
        }
      })
    }

    return result
  }, [month.id])

  const events = React.useMemo(() => {
    let forCalendar = []
    let forList = []

    const selectedClub = club

    //     arranging data for calendar
    for (let i = 1; i <= daysInMonth; i++) {
      const thisDate = new Date(new Date().getUTCFullYear(), month.id - 1, i)
      const thisDayString = thisDate.toLocaleDateString("en-US", {
        weekday: "short",
      })

      const eventThisDay = selectedClub?.events?.find(event => {
        const dateTimeFrom = new Date(
          new Date(event.dateTimeFrom).getUTCFullYear(),
          new Date(event.dateTimeFrom).getUTCMonth(),
          new Date(event.dateTimeFrom).getUTCDate()
        )
        const dateTimeTo = new Date(
          new Date(event.dateTimeTo).getUTCFullYear(),
          new Date(event.dateTimeTo).getUTCMonth(),
          new Date(event.dateTimeTo).getUTCDate()
        )

        return thisDate >= dateTimeFrom && thisDate <= dateTimeTo
      })

      if (eventThisDay) {
        console.log(eventThisDay)
        forCalendar.push(
          <CalendarItem
            empty={false}
            title={eventThisDay.title}
            itemId={eventThisDay.id}
            slug={eventThisDay.slug}
            img={eventThisDay.image}
            date={{ dayWord: thisDayString, dayNumber: i }}
          />
        )
      } else {
        forCalendar.push(
          <CalendarItem
            empty={true}
            title="No event"
            img={test}
            date={{ dayWord: thisDayString, dayNumber: i }}
          />
        )
      }
    }

    //     arranging data for list
    selectedClub?.events?.forEach(event => {
      // console.log(event)
      const formattedDateFrom = new Date(
        new Date(event.dateTimeFrom).getUTCFullYear(),
        new Date(event.dateTimeFrom).getUTCMonth(),
        new Date(event.dateTimeFrom).getUTCDate()
      )
      const formattedDateTo = new Date(
        new Date(event.dateTimeTo).getUTCFullYear(),
        new Date(event.dateTimeTo).getUTCMonth(),
        new Date(event.dateTimeTo).getUTCDate()
      )
      const monthFrom = formattedDateFrom.getUTCMonth() + 1
      const monthTo = formattedDateTo.getUTCMonth() + 1
      // const monthFromString = formattedDateFrom.toLocaleDateString("en-US", {
      //   month: "short",
      // })

      if (monthFrom === month.id || month.id === "all") {
        const dateToDisplay =
          monthFrom !== monthTo
            ? `${formattedDateFrom.toLocaleDateString("en-US", {
                day: "numeric",
                month: "short",
              })} -
                ${formattedDateTo.toLocaleDateString("en-US", {
                  day: "numeric",
                  month: "short",
                })}`
            : `${formattedDateFrom.toLocaleDateString("en-US", {
                day: "numeric",
                month: "short",
              })} -
                ${formattedDateTo.getDate()}`
        console.log(event)
        forList.push(
          <EventListItem
            title={event.title}
            itemId={event.id}
            slug={event.slug}
            date={dateToDisplay}
            img={event.image}
          />
        )
      }
    })
    console.log(club)

    return {
      forCalendar,
      forList,
    }
  }, [month.id, daysInMonth, club])

  const clubsOptions = allowAllClubsOption
    ? [{ id: "all", label: "All", events: [] }, ...clubs]
    : clubs

  const monthOptions = allowAllClubsOption
    ? [{ id: "all", label: "All" }, ...months]
    : months

  return (
    <Layout
      location={location}
      title="Upcoming Events"
      ctaColor="bg-yellowdark-50"
      textCtaColor="text-fontpurple-50"
      fullHeader
    >
      <div className="bg-white pb-4">
        <Seo title="Upcoming Events" />
        <div className="bg-fontpurple-50 h-96 flex items-center">
          <Header additionalClassName="text-center mt-20 ml-4">
            Upcoming events
          </Header>
        </div>
        {/*   CALENDAR FILTERS     */}
        <div
          className="relative bg-tealCustom-50 rounded-[2rem] lg:rounded-full text-white text-center -mt-14 max-w-7xl mx-auto px-0
          sm:px-6 lg:px-0
          "
        >
          <div className="py-10 flex flex-row min-w-full justify-around items-center">
            <div className="flex flex-row">
              <div className="flex flex-row flex-nowrap">
                <LineSelect
                  label="Club"
                  options={clubsOptions}
                  value={club}
                  defaultValue={club}
                  onChange={setClub}
                  additionalClassName="md:mr-10 mr-2"
                />
                <LineSelect
                  label="Month"
                  options={monthOptions}
                  value={month}
                  defaultValue={month}
                  onChange={setMonth}
                  additionalClassName="mr-2"
                />
              </div>
            </div>

            <div className="flex flex-row flex-nowrap">
              <OutlinedButton
                additionalClassName={`md:mr-2 mr-2 w-10 h-7 md:w-auto md:h-9
                        ${
                          display === "list"
                            ? "text-purplebglight-50 bg-white"
                            : ""
                        }
                    `}
                onClick={() => setDisplay("list")}
              >
                <FontAwesomeIcon icon={faList} className="md:mr-4 h-4 w-4" />
                <span className="hidden md:inline-block font-primary-bold">
                  List
                </span>
              </OutlinedButton>
              <OutlinedButton
                additionalClassName={`w-10 h-7 md:w-auto md:h-9
                        ${
                          display === "calendar"
                            ? "text-purplebglight-50 bg-white"
                            : ""
                        }
                    `}
                onClick={() => setDisplay("calendar")}
              >
                <FontAwesomeIcon
                  icon={faCalendar}
                  className="md:mr-4 h-4 w-4"
                />
                <span className="hidden md:inline-block font-primary-bold">
                  Calendar
                </span>
              </OutlinedButton>
            </div>
          </div>
        </div>

        {/*    CALENDAR ITEMS    */}
        <div className="mt-10 max-w-7xl mx-auto pb-4">
          {display === "list" && club.id === "all" && (
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-2">
              {allEventsByMonth}
            </div>
          )}
          {display === "list" && club.id !== "all" && (
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-2">
              {events.forList}
            </div>
          )}
          {display === "calendar" && (
            <div className="grid grid-cols-1 lg:grid-cols-7 gap-4 p-2">
              {events.forCalendar}
            </div>
          )}
        </div>
      </div>
      <LatestNews
        eventsCarouselBg="white"
        eventsCarouselFontColor="#009ADE"
        cardBorderColorClass="border-white-50"
        cardFontColorClass="text-white"
        type={"mainPage"}
        blogs={data.allStrapiBlog.edges}
      />
      <ThreeColumnGallery
        bgGallery="bg-black"
        titleColor={"text-white"}
        galleries={data.allStrapiGallery.nodes}
        galleryCtaTextColor={"text-white"}
        galleryCtaBorderColor={"border-white"}
        type="mainPage"
      />
      {/* <Instagram
        type={"mainPage"}
        title={"zrcebeach_official"}
        // instaNodes={data.allInstaNode.edges}
      /> */}
    </Layout>
  )
}

export default Events

export const pageQuery = graphql`
  query MyQuerySecond {
    allStrapiClub(sort: { fields: events___dateTimeFrom, order: DESC }) {
      edges {
        node {
          id
          clubName
          events {
            id
            title
            dateTimeFrom
            dateTimeTo
            createdAt
            slug

            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, quality: 80)
                }
              }
            }
          }
        }
      }
    }
    allStrapiEvent {
      nodes {
        id
        title
        dateTimeFrom
        dateTimeTo
        slug
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 80)
            }
          }
        }
      }
    }
    allStrapiBlog {
      edges {
        node {
          slug
          featuredImage {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: DOMINANT_COLOR, quality: 100)
              }
            }
          }
          category {
            slug
          }
          title
          publishedAt
        }
      }
    }
    allStrapiGallery(limit: 3) {
      nodes {
        id
        title
        images {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 10)
            }
          }
        }
      }
    }
  }
`
